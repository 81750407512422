import { Spin } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRoutes } from "react-router-dom"
import STORAGE, { getStorage } from "src/lib/storage"
import { StoreContext } from "src/lib/store"
import CommonService from "src/services/CommonService"
import "./App.scss"
import ModalLoading from "./components/Modal/Loading"
import { getListSystemKey, getStaticNav, setListTabs } from "./redux/appGlobal"
import { setBanner, setColors, setEvent, setFooter } from "./redux/banner"
import ROUTER from "./router"
import GuestServices from "./services/GuestServices"
import RoleService from "./services/RoleService"
import LandingRoutes from "./pages/LandingPage/LandingRoutes"

//landing Page

const Charts = React.lazy(() => import("./pages/LandingPage/Charts"))
const Rules = React.lazy(() => import("./pages/LandingPage/Rules"))
const VoteAuth = React.lazy(() => import("./pages/LandingPage/VoteAuth"))
const HomeLanding = React.lazy(() => import("./pages/LandingPage/HomeLanding"))
const DetailAuthor = React.lazy(() =>
  import("./pages/LandingPage/DetailAuthor"),
)

const NewsLanding = React.lazy(() => import("./pages/LandingPage/NewsLanding"))
const NewsDetailLanding = React.lazy(() =>
  import("./pages/LandingPage/NewsDetail"),
)

// ANONYMOUS
const LoginGoogle = React.lazy(() => import("./pages/LoginGoogle"))
const LoginZalo = React.lazy(() => import("./pages/LoginZalo"))

const PublicRouters = React.lazy(() => import("./pages/PublicRouters"))
const Home = React.lazy(() => import("./pages/ANONYMOUS/Home"))
const ZALO_CONFIRM = React.lazy(() => import("./pages/ANONYMOUS/ZaloConfirm"))
const News = React.lazy(() => import("./pages/ANONYMOUS/News"))

const Search = React.lazy(() => import("./pages/Search"))
const LibraryImage = React.lazy(() => import("./pages/ANONYMOUS/Images"))
const Library = React.lazy(() => import("./pages/ANONYMOUS/Library"))

const NewsDetail = React.lazy(() => import("./pages/ANONYMOUS/NewsDetail"))
const Contact = React.lazy(() => import("./pages/ANONYMOUS/Contact"))
const ListVideo = React.lazy(() => import("./pages/ANONYMOUS/ListVideo"))
const SvgViewer = React.lazy(() => import("./pages/SvgViewer"))
const NotFound = React.lazy(() => import("./pages/NotFound"))

const Introduce = React.lazy(() => import("./pages/ANONYMOUS/Introduce"))
const IntroductionOverview = React.lazy(() =>
  import("./pages/ANONYMOUS/IntroductionOverview"),
)
const ServicePage = React.lazy(() => import("./pages/ANONYMOUS/ServicePage"))
const Services = React.lazy(() => import("./pages/ANONYMOUS/Services"))
const DetailProduct = React.lazy(() =>
  import("./pages/ANONYMOUS/Product/Detail"),
)
const FindDocumentPage = React.lazy(() =>
  import("./pages/ANONYMOUS/FindDocument"),
)
const OrganizationPage = React.lazy(() =>
  import("./pages/ANONYMOUS/Organization"),
)

// USER
const PrivateRoutes = React.lazy(() => import("./pages/PrivateRoutes"))
const MyAccount = React.lazy(() => import("./pages/USER/MyAccount"))

// ADMIN
const OverviewPage = React.lazy(() => import("./pages/ADMIN/Overview"))
const AminRoutes = React.lazy(() => import("./pages/ADMIN/AminRoutes"))
const Department = React.lazy(() => import("./pages/ADMIN/Department"))
const LegalDoc = React.lazy(() => import("./pages/ADMIN/LegalDoc"))
const RequestSupport = React.lazy(() =>
  import("src/pages/ADMIN/RequestSupport"),
)

const BannerFooter = React.lazy(() => import("./pages/ADMIN/BannerFooter"))
const Image = React.lazy(() => import("./pages/ADMIN/Image"))
const VideoManager = React.lazy(() => import("./pages/ADMIN/VideoManager"))
const Document = React.lazy(() => import("./pages/ADMIN/Document"))
const PostManager = React.lazy(() => import("./pages/ADMIN/PostManager"))
const Role = React.lazy(() => import("./pages/ADMIN/Role"))
const Tags = React.lazy(() => import("./pages/ADMIN/Tags"))
const CategoryPost = React.lazy(() => import("./pages/ADMIN/CategoryPost"))
const ListContact = React.lazy(() => import("./pages/ADMIN/ListContact"))
const ActivityLog = React.lazy(() => import("./pages/ADMIN/HistoryActive"))
const OrganizationSystem = React.lazy(() =>
  import("./pages/ADMIN/OrganizationSystem"),
)
const StatisticPage = React.lazy(() => import("./pages/ADMIN/Statistic"))

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <Spin />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

function App() {
  const { userStore } = useContext(StoreContext)
  const [, setUser] = userStore
  const isLogin = getStorage(STORAGE.TOKEN)
  const dispatch = useDispatch()
  const { modalLoading } = useSelector(state => state.common)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getBaner()
  }, [])
  const [bannerTop, setBannerTop] = useState()
  const getBaner = async () => {
    const res = await GuestServices.getListBanner()
    if (res?.isError) return
    setBannerTop(pre => res?.Object?.BannerFooter?.ListBanner?.[0])
    dispatch(setBanner(res?.Object?.BannerFooter?.ListBanner))
    dispatch(
      setFooter({
        Footer: res?.Object?.BannerFooter?.Footer,
        Map: res?.Object?.BannerFooter?.Map,
      }),
    )
    dispatch(setBanner(res?.Object?.BannerFooter?.ListBanner))
    dispatch(setEvent(res?.Object?.Event))
    dispatch(setColors(res?.Object?.AppConfig?.Data))
  }
  useEffect(() => {
    getSystemKey()
  }, [])

  useEffect(() => {
    if (!!isLogin) getData()
  }, [isLogin])

  const getSystemKey = async () => {
    const res = await CommonService.getSystemKey("All")
    if (res.IsError) return
    dispatch(getListSystemKey(res.Object))
  }
  const getData = async () => {
    try {
      setLoading(true)
      setUser(getStorage(STORAGE.USER_INFO))
      if (getStorage(STORAGE.USER_INFO).AccountType !== 2) {
        const resp = await RoleService.getListTab()
        if (resp.isOk) {
          dispatch(setListTabs(resp.Object))
        }
      }
      getSystemKey()
      const ress = await GuestServices.getStaticNav()
      if (ress.IsError) return
      dispatch(getStaticNav(ress.Object))
    } finally {
      setLoading(false)
    }
  }
  const routes = [
    {
      path: ROUTER.SVG_VIEWER,
      element: (
        <LazyLoadingComponent>
          <SvgViewer />
        </LazyLoadingComponent>
      ),
    },
    {
      path: ROUTER.LOGIN_GOOGLE,
      element: (
        <LazyLoadingComponent>
          <LoginGoogle />
        </LazyLoadingComponent>
      ),
    },
    {
      path: `${ROUTER.LOGIN_ZALO}`,
      // path: ROUTER.LOGIN_ZALO,
      element: (
        <LazyLoadingComponent>
          <LoginZalo />
        </LazyLoadingComponent>
      ),
    },

    // Landing Page
    {
      element: (
        <LazyLoadingComponent>
          <LandingRoutes />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.BINH_CHON,
          element: (
            <LazyLoadingComponent>
              <HomeLanding />
            </LazyLoadingComponent>
          ),
        },
        {
          path: `${ROUTER.CHI_TIET_TAC_GIA}/:id`,
          element: (
            <LazyLoadingComponent>
              <DetailAuthor />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.THE_LE,
          element: (
            <LazyLoadingComponent>
              <Rules />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.DANH_SACH_TAC_GIA,
          element: (
            <LazyLoadingComponent>
              <VoteAuth />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.BANG_XEP_HANG,
          element: (
            <LazyLoadingComponent>
              <Charts />
            </LazyLoadingComponent>
          ),
        },
        // {
        //   path: ROUTER.GIAI_THUONG,
        //   element: (
        //     <LazyLoadingComponent>
        //       <Prize />
        //     </LazyLoadingComponent>
        //   ),
        // },
        {
          path: `${ROUTER.TIN_TUC_BINH_CHON}/:id`,
          element: (
            <LazyLoadingComponent>
              <NewsLanding />
            </LazyLoadingComponent>
          ),
        },

        {
          path: `${ROUTER.CHI_TIET_TIN_TUC_BINH_CHON}/:id`,
          element: (
            <LazyLoadingComponent>
              <NewsDetailLanding />
            </LazyLoadingComponent>
          ),
        },
      ],
    },
    // ADMIN
    {
      element: (
        <LazyLoadingComponent>
          <AminRoutes />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.YEU_CAU_HO_TRO,
          element: (
            <LazyLoadingComponent>
              <RequestSupport />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.BANNER_FOOTER,
          element: (
            <LazyLoadingComponent>
              <BannerFooter />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.VAN_BAN,
          element: (
            <LazyLoadingComponent>
              <LegalDoc />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.HINH_ANH,
          element: (
            <LazyLoadingComponent>
              <Image />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.VIDEO,
          element: (
            <LazyLoadingComponent>
              <VideoManager />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.TAI_LIEU,
          element: (
            <LazyLoadingComponent>
              <Document />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_BA,
          element: (
            <LazyLoadingComponent>
              <ListContact />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.PHONG_BAN_CHUC_VU,
          element: (
            <LazyLoadingComponent>
              <Department />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.DANH_MUC_THE,
          element: (
            <LazyLoadingComponent>
              <Tags />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.PHAN_QUYEN,
          element: (
            <LazyLoadingComponent>
              <Role />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_BAI_VIET,
          element: (
            <LazyLoadingComponent>
              <PostManager />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.LOAI_VAN_BAN,
          element: (
            <LazyLoadingComponent>
              <CategoryPost type={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CO_QUAN_BAN_HANH,
          element: (
            <LazyLoadingComponent>
              <CategoryPost type={5} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.LS_HOAT_DONG,
          element: (
            <LazyLoadingComponent>
              <ActivityLog />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.HE_THONG_TO_CHUC,
          element: (
            <LazyLoadingComponent>
              <OrganizationSystem />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TONG_QUAN,
          element: (
            <LazyLoadingComponent>
              <OverviewPage />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THONG_KE,
          element: (
            <LazyLoadingComponent>
              <StatisticPage />
            </LazyLoadingComponent>
          ),
        },
      ],
    },
    //  USER
    {
      element: (
        <LazyLoadingComponent>
          <PrivateRoutes />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.TAI_KHOAN_CUA_TOI,
          element: (
            <LazyLoadingComponent>
              <MyAccount />
            </LazyLoadingComponent>
          ),
        },
      ],
    },
    //  ANONYMOUS
    {
      element: (
        <LazyLoadingComponent>
          <PublicRouters />
        </LazyLoadingComponent>
      ),
      children: [
        {
          path: ROUTER.HOME,
          element: (
            <LazyLoadingComponent>
              <Home />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.ZALO_CONFIRM,
          element: (
            <LazyLoadingComponent>
              <ZALO_CONFIRM />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIM_KIEM,
          element: (
            <LazyLoadingComponent>
              <Search />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THU_VIEN,
          element: (
            <LazyLoadingComponent>
              <Library />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.THU_VIEN_HINH_ANH,
          element: (
            <LazyLoadingComponent>
              <LibraryImage />
            </LazyLoadingComponent>
          ),
        },
        {
          path: "/thu-vien-hinh-anh",
          element: (
            <LazyLoadingComponent>
              <LibraryImage />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.LIEN_HE,
          element: (
            <LazyLoadingComponent>
              <Contact />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DICH_VU,
          element: (
            <LazyLoadingComponent>
              <ServicePage />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CAC_GOI_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <Services type={1} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.CHI_TIET_DICH_VU,
          element: (
            <LazyLoadingComponent>
              <DetailProduct />
            </LazyLoadingComponent>
          ),
        },

        {
          path: `${ROUTER.DANH_SACH_TIN_TUC}/:id`,
          element: (
            <LazyLoadingComponent>
              <News />
            </LazyLoadingComponent>
          ),
        },

        {
          path: ROUTER.TIN_TUC_CHI_TIET,
          element: (
            <LazyLoadingComponent>
              <NewsDetail />
            </LazyLoadingComponent>
          ),
        },

        {
          path: `${ROUTER.GIOI_THIEU}/:id`,
          element: (
            <LazyLoadingComponent>
              <Introduce />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.GIOI_THIEU_TONG_QUAN,
          element: (
            <LazyLoadingComponent>
              <IntroductionOverview />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIM_KIEM_VAN_BAN,
          element: (
            <LazyLoadingComponent>
              <FindDocumentPage Type={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: `${ROUTER.TIM_KIEM_VAN_BAN}/:id`,
          element: (
            <LazyLoadingComponent>
              <FindDocumentPage Type={2} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TIM_KIEM_TAI_LIEU,
          element: (
            <LazyLoadingComponent>
              <FindDocumentPage Type={4} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: `${ROUTER.TIM_KIEM_TAI_LIEU}/:id`,
          element: (
            <LazyLoadingComponent>
              <FindDocumentPage Type={4} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.TAI_LIEU_HDNV,
          element: (
            <LazyLoadingComponent>
              <FindDocumentPage Type={4} isLibrary={true} />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.DANH_SACH_VIDEO,
          element: (
            <LazyLoadingComponent>
              <ListVideo />
            </LazyLoadingComponent>
          ),
        },
        {
          path: ROUTER.HE_THONG_TO_CHUC,
          element: (
            <LazyLoadingComponent>
              <OrganizationPage />
            </LazyLoadingComponent>
          ),
        },
      ],
    },
    {
      path: "*",
      element: (
        <LazyLoadingComponent>
          <NotFound />
        </LazyLoadingComponent>
      ),
    },
  ]
  const element = useRoutes(routes)
  return (
    <div className="layout-center">
      <div className="layout-max-width">
        {loading ? (
          <div className="loading-center" style={{ height: "100vh" }}>
            <Spin />
          </div>
        ) : (
          element
        )}
      </div>
      {!!modalLoading && <ModalLoading />}
    </div>
  )
}

export default App
